@import '~glightbox/dist/css/glightbox.min.css';

.product-show-header {
    .header-top {
        position: static !important;
    }

    .bg-opacity-modal.bg-white-active {
        top: 150px;
    }

    @media #{$responsive-query} {
        .navbar.active {
            top: 30px;
        }
    }
}

.product-variant-images-wrapper {
    .carousel-thumbnails-wrapper {
        flex-basis: 64px;

        button {
            &:hover {
                border-color: $green-700 !important;
            }
        }

        .carousel-thumbnails {
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
        }

        @include media-breakpoint-up(xl) {
            flex-basis: 79px;
        }

        @include media-breakpoint-up(xxl) {
            flex-basis: 94px;
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
        margin-left: 8px;
    }

    .carousel-control-next {
        justify-content: flex-end;
        margin-right: 8px;
    }

    @include media-breakpoint-up(lg) {
        .carousel.slide {
            &.vertical {
                max-height: 501px;
            }

            max-height: 376px;
        }
    }

    @include media-breakpoint-up(xl) {
        .carousel.slide {
            &.vertical {
                max-height: 600px;
            }

            max-height: 451px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .carousel.slide {
            &.vertical {
                max-height: 700px;
            }

            max-height: 526px;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-right: -20px;
        margin-left: -20px;
    }
}

.cross-sell-container {
    .cross-sell-slider-wrapper {
        &::-webkit-scrollbar {
            @include media-breakpoint-up(lg) {
                height: 0;
                background: transparent;
            }
        }
    }

    .arrow-prev {
        left: -7px;
    }

    .arrow-next {
        right: -7px;
    }

    .arrow-prev,
    .arrow-next {
        height: 107px;

        span {
            width: 28px;
            height: 28px;
            box-shadow: 0 4px 12px 0 #0000001a;
        }
    }

    @include media-breakpoint-up(lg) {
        .arrow-prev {
            left: -8px;
        }

        .arrow-next {
            right: -8px;
        }

        .arrow-prev,
        .arrow-next {
            span {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.cross-sell-item {
    min-width: 111px;
    max-width: 111px;

    .image {
        margin: 2px;

        img {
            width: 107px;
            height: 107px;
        }

        .icon {
            display: none;
            border-top-right-radius: 2px;
            font-size: 16px;
        }
    }

    &:hover {
        .image {
            box-shadow: 0 0 0 1px $gray-950;
        }
    }

    &.selected {
        .image {
            box-shadow: 0 0 0 2px $gray-950;
        }

        .icon-check {
            display: block;
        }

        &:hover {
            .icon-x {
                display: block;
            }
        }
    }
}

.product-tags {
    &::-webkit-scrollbar {
        display: none;
    }

    > div {
        &:empty {
            display: none !important;
        }

        &:last-of-type:not(:empty) {
            margin-bottom: 12px;
        }
    }
}

.cart-modal {
    .cross-sell-modal {
        scrollbar-width: none;

        .cross-sell-modal-card {
            width: 248px;
        }
    }

    .modal-buttons {
        .short-text {
            display: none;
        }
    }

    .hover-image {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s;
    }

    @include media-breakpoint-up(lg) {
        .cross-sell-modal-card {
            &:hover {
                .hover-image {
                    opacity: 1;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .cart-modal-body {
            img {
                width: 40px;
                height: 40px;
            }
        }

        .cross-sell-modal {
            .cross-sell-modal-card {
                width: 42%;
            }
        }
    }

    @media (width < 414px) {
        .modal-buttons {
            .modal-button {
                width: auto !important;
            }

            .long-text {
                display: none;
            }

            .short-text {
                display: block;
            }
        }
    }
}
